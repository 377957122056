<template>
  <div class="home d-flex flex-column min-vh-100">
    <section class="hero bg-light text-center py-5 mb-5">
      <div class="container py-5">
        <h1 class="display-4">Welcome to Finpath</h1>
        <p class="lead">
          Your journey to financial freedom starts here. Take control of your
          expenses, pay off your debt, and secure a brighter future.
        </p>
        <router-link to="/about" class="btn btn-dark btn-lg mt-4">
          Learn more
        </router-link>
      </div>
    </section>

    <section class="features py-5 my-5">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="feature-box text-center">
              <i class="fas fa-piggy-bank fa-3x mb-3"></i>
              <h3>Save More</h3>
              <p>
                Learn how to budget effectively and save money for the things
                that matter most.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature-box text-center">
              <i class="fas fa-chart-line fa-3x mb-3"></i>
              <h3>Reduce Debt</h3>
              <p>
                We offer strategies to help you tackle debt head-on and reduce
                it faster than you thought possible.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature-box text-center">
              <i class="fas fa-unlock-alt fa-3x mb-3"></i>
              <h3>Gain Freedom</h3>
              <p>
                Achieve the ultimate goal of financial independence and live
                life on your own terms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="footer bg-dark text-white text-center py-4 mt-auto">
      <div class="container">
        <p>&copy; 2024 Pearl Horizon Inc. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
.hero {
  background-color: #f8f9fa;
}

.feature-box {
  padding: 20px;
}

.feature-box i {
  color: #007bff;
}

.footer {
  background-color: #343a40;
  color: #fff;
  width: 100%;
}
</style>
